<template>
  <vxe-pulldown ref="countryPulldown" transfer>
    <template v-slot>
      <vxe-input
        v-model="countryregion.title"
        suffix-icon="fa fa-caret-down"
        placeholder="目的地国家/地区"
        @keyup="keyupCityEvent"
        @focus="focusCityEvent"
        @suffix-click="suffixCityClick"
        class="input"
      ></vxe-input>
    </template>
    <template v-slot:dropdown>
      <div class="my-dropdown4">
        <div class="countrysbox">
          <!-- <p class="tips">支持直接输入可搜索城市(支持汉字/拼音)</p> -->
          <ul>
            <li
              v-for="(item, index) in groups"
              :key="item"
              :class="{ active: item === group }"
              :style="`${index === 0 ? 'margin-left: -20px' : ''}`"
              @click="group = item"
            >
              {{ item }}
            </li>
          </ul>
          <div class="tab-content">
            <div class="countrygroup">
              <span
                v-for="item in groupdata[group]"
                :key="item._id"
                :data-value="item._id"
                @click="doSelect(item)"
              >
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import _ from "underscore";
import "xe-utils";
export default {
  name: "CountryRegions",
  props: ["code"],
  data() {
    return {
      countryregion: {
        title: "",
        _id: "",
        name: "",
        name_en: "",
        code: "",
      },
      group: "热门地区",
      groups: ["热门地区", "ABCD", "EFGH", "IJKL", "MNOP", "QRST", "UVWX", "YZ"],
      groupdata: {
        热门地区: [],
        ABCD: [],
        EFGH: [],
        IJKL: [],
        MNOP: [],
        QRST: [],
        UVWX: [],
        YZ: [],
      },
    };
  },
  watch: {
    code(code) {
      this.init(code);
    },
  },
  activated() {
    this.getdata();
  },
  methods: {
    init(code) {
      if (code && code !== this.countryregion.code) {
        let group = _.find(this.groups, (n) => n.indexOf(code[0]) > -1);
        if (group) {
          this.group = group;
          let countryregion = _.findWhere(this.groupdata[group], { code });
          if (countryregion) {
            this.countryregion = countryregion;
            this.$emit("setcountryregion", countryregion);
          }
        }
      }
    },
    getdata() {
      this.$api
        .doAPI("/countryregions")
        .then((res) => {
          if (res.code === 0) {
            console.log(res.data);
            this.group = res.data.group;
            this.groups = res.data.groups;
            this.groupdata = res.data.groupdata;
            this.init(this.code);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    doSelect(countryregion) {
      this.countryregion = countryregion;
      this.$emit("setcountryregion", countryregion);
      this.$refs.countryPulldown.hidePanel();
    },
    focusCityEvent() {
      console.log("focusCityEvent");
      this.$refs.countryPulldown.showPanel();
    },
    keyupCityEvent() {
      console.log("keyupCityEvent");
    },
    suffixCityClick() {
      console.log("suffixCityClick");
      this.$refs.countryPulldown.togglePanel();
    },
    cellClickCityEvent({ row }) {
      console.log("cellClickCityEvent", row);
      this.$refs.countryPulldown.hidePanel();
    },
  },
};
</script>
<style lang="less" scoped>
.vxe-pulldown {
  width: 100%;
  .input {
    font-size: 16px;
    height: 48px;
    width: 100%;
  }
}
.countrysbox ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  border-bottom: 1px solid #ddd;
}
.countrysbox {
  padding: 10px;
}
.countrysbox ul li {
  list-style: none;
  padding: 5px 0;
  font-size: 14px;
  cursor: pointer;
  display: inline;
  color: #355976;
}
.countrysbox ul li.active {
  border-bottom: 1px solid #286ed6;
}
.countrysbox .countrygroup {
  margin-top: 10px;
}
.countrysbox .countrygroup span {
  position: relative;
  width: 20%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: white;
  padding: 5px;
  right: auto;
  top: auto;
  word-wrap: break-word;
  color: #4a4a4a;
  font-size: 12px;
}
</style>
