<template>
  <div class="site-footer">
    <div class="widget-area">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-2">
            <div class="widget">
              <h3 class="widget-title">联系我们</h3>
              <address>河南自贸试验区郑州片区（经开）第八大街160号附5号综合保税区</address>
              <a href="javascript:;">电话: 132-5348-2022</a>
              <br />
              <a href="mailto:info@company.com">services@zhengboec.com</a>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2">
            <div class="widget">
              <h3 class="widget-title">集团业务</h3>
              <ul class="no-bullet">
                <li>
                  <a href="javascript:;">航空包机</a>
                </li>
                <li>
                  <a href="javascript:;">货物运输</a>
                </li>
                <li>
                  <a href="javascript:;">电商产业园</a>
                </li>
                <li>
                  <a href="javascript:;">海外仓服务</a>
                </li>
                <li>
                  <a href="/business">更多业务</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2">
            <div class="widget">
              <h3 class="widget-title">友情链接</h3>
              <ul class="no-bullet">
                <li>
                  <a href="http://www.ems.com.cn/?from=openv" target="_bank">中国邮政</a>
                </li>
                <li>
                  <a href="https://www.mabangerp.com/index.htm" target="_bank">马帮ERP</a>
                </li>
                <li>
                  <a href="https://www.17track.net/zh-cn" target="_bank">17track</a>
                </li>
                <li>
                  <a href="https://seller.dhgate.com/promotion/xindhgate.html" target="_bank">敦煌网</a>
                </li>
                <li>
                  <a href="https://www.csair.com/cn/" target="_bank">中国南方航空</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2">
            <div class="widget center">
              <h3 class="widget-title">集运服务</h3>
              <div class="code">
                <img src="@/assets/images/qj-qrcode.jpg" />
                <p>“卿稷速递”小程序</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2">
            <div class="widget center">
              <h3 class="widget-title">集运公众号</h3>
              <div class="code">
                <img src="@/assets/images/df-wechat.jpg" />
                <p>“大方集运”公众号</p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2">
            <div class="widget center">
              <h3 class="widget-title">关注我们</h3>
              <div class="code">
                <img src="@/assets/images/wxcode.jpg" />
                <p>关注“正博电子商务”服务号</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-footer">
      <div class="container">
        <nav class="footer-navigation">
          <a href="javascript:;" v-for="menuitem in menuitems" :key="menuitem.path" @click="goto(menuitem.path)">
            {{ menuitem.title }}
          </a>
        </nav>
        <div class="colophon">
          <p>
            Copyright
            {{ $utils.dateTime(new Date(), "YYYY") }}
            河南正博电子商务有限公司. All rights reserved.
            <a href="https://beian.miit.gov.cn/" style="color:#898b8d;">{{ ICP }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      menuitems: [
        { title: "首页", path: "/" },
        { title: "产品与服务", path: "/products" },
        { title: "服务与支持", path: "/services" },
        { title: "集团业务", path: "/business" },
        { title: "新闻中心", path: "/news" },
        { title: "关于我们", path: "/about" },
        { title: "联系我们", path: "/contact" }
      ],
      ICP: "豫ICP备19040929号-1",
      ICPS: {
        "henanzhengbo.cn": "豫ICP备19040929号-1",
        "www.henanzhengbo.cn": "豫ICP备19040929号-1",
        "hnhedao.cn": "豫ICP备19040929号-2",
        "www.hnhedao.cn": "豫ICP备19040929号-2",
        "hnlidi.cn": "豫ICP备19040929号-3",
        "www.hnlidi.cn": "豫ICP备19040929号-3",
        "hnbaituo.cn": "豫ICP备19040929号-4",
        "www.hnbaituo.cn": "豫ICP备19040929号-4",
        "zhengboec.com": "豫ICP备19040929号-5",
        "www.zhengboec.com": "豫ICP备19040929号-5",
        "dfsupplychain.com": "豫ICP备19040929号-6",
        "www.dfsupplychain.com": "豫ICP备19040929号-6",
        "lidilog.com": "豫ICP备19040929号-7",
        "www.lidilog.com": "豫ICP备19040929号-7",
      }
    };
  },
  mounted() {
    this.checkICP();
  },
  methods: {
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
    checkICP() {
      console.log(window.location.host);
      let ICP = this.ICPS[window.location.host];
      if (ICP) this.ICP = ICP;
    }
  }
};
</script>
<style lang="less" scoped>
.code img {
  width: 100px;
}
</style>