import axios from 'axios'
const instance = axios.create({
  baseURL: process.env.BASE_API,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
instance.interceptors.request.use(config => {
  // config.headers.Authorization = `${localStorage.getItem('zbl_web.token')}`;
  return config;
}, error => {
  return Promise.reject(error);
})
//响应拦截器
instance.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});
// 封装get方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: params }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    })
  })
}
// 封装post方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function upload(formData, filetype = "image", onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    formData.set('filetype', filetype);
    instance.post(`/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Authorization': `${localStorage.getItem('zbl_web.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doUpload(url, formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Authorization': `${localStorage.getItem('zbl_web.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doAPI(url, Data = {}) {
  return post(url, Data);
}
export default {
  get, post, upload, doUpload, doAPI,
};