import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
moment.locale('zh_cn');

function makeUUID() {
  return uuidv4().replace(/-/g, '');
}
function dateTime(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "";
}
function fromNow(date) {
  return moment(date).fromNow();
}
function downloadBrowser({ name, href }) {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}
export default {
  makeUUID,
  uuid: makeUUID,
  dateTime,
  fromNow,
  downloadBrowser,
};