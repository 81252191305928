<template>
  <div>
    <Header />
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <Footer />
  </div>
</template>

<script>
import Header from "@/common/Header";
import Footer from "@/common/Footer";
export default {
  name: "BasicLayout",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
  },
  computed: {},
  created() {},
  mounted() {},
  activated() {},
  watch: {},
  methods: {},
};
</script>
<style>
</style>