<template>
  <div class="about">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>关于我们</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>关于我们</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="row">
            <div class="col-md-2 product-list" style="padding:0;">
              <el-collapse v-model="activeName">
                <el-collapse-item title="关于我们" name="1">
                  <div :class="currentIndex == 11 ? 'item active' : 'item'" @click="changeProject(11)">关于正博</div>
                  <div :class="currentIndex == 12 ? 'item active' : 'item'" @click="changeProject(12)">企业文化小故事</div>
                  <div :class="currentIndex == 13 ? 'item active' : 'item'" @click="changeProject(13)">员工活动</div>
                  <div :class="currentIndex == 14 ? 'item active' : 'item'" @click="changeProject(14)">企业招聘</div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="col-md-10">
              <div class="mcontent" v-if="currentIndex === 11">
                <h1 class="entry-title">河南速正博电子商务有限公司</h1>
                <div class="info">
                  <p>
                    河南正博电子商务有限公司成立于2016年，是集跨境电商自营，国内国际物流、保税仓及海外仓服务、专线包机、国内干线运输、供应链金融、跨境产业园运营于一体的全产业链科技公司。下属大方供应链管理有限公司、合道人力资源有限公司、利滴物流有限公司。公司服务郑州、深圳、上海、北京、杭州、义乌、西安等城市的货代公司及电商直客，业务覆盖邮政E邮宝、邮政小包业务、自营专线业务、代理报关、清关业务等，2020年实现出口报关758.31万件，贸易额人民币11.4亿元，2021年10月底已完成进出口贸易额44.2
                    亿元。
                  </p>
                  <p>
                    产业园板块目前运营河南正博跨境电商产业园、受托运营江西邮政跨境电商产业园，招商入驻各类电商企业20余家；跨境自营板块2021年销售额3000万元。
                  </p>
                  <p> 航空包舱团队2020年执飞航班 122次，运输货物1004吨，销售3473万元；2021年截止目前已执飞航班258次，运输货物 2760吨，销售收入1.1亿元。</p>
                </div>
                <div class="features">
                  <div class="feature-numbered">
                    <div class="num">1</div>
                    <h2 class="feature-title">企业文化</h2>
                    <p>沟通、互助、时效、口碑。</p>
                  </div>
                  <div class="feature-numbered">
                    <div class="num">2</div>
                    <h2 class="feature-title">企业愿景</h2>
                    <p>助力中国制造业走向海外。</p>
                  </div>
                  <div class="feature-numbered">
                    <div class="num">3</div>
                    <h2 class="feature-title">企业核心价值观</h2>
                    <p>诚信、高效、专业、敬业。</p>
                  </div>
                  <div class="feature-numbered">
                    <div class="num">4</div>
                    <h2 class="feature-title">企业宗旨</h2>
                    <p>开拓、共赢、远略。</p>
                  </div>
                </div>
              </div>
              <div class="mcontent corporate" v-if="currentIndex === 12">
                <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                  <li v-for="i in count" :key="i" class="infinite-list-item">
                    <el-card>
                     <h1 style="margin:0;">{{i}}</h1>
                     <p>上周六的下午5点多钟，我正在开车送孩去学校，客服的同事在大方订舱群里发消息说需要帮助解决一个小问题，并发来了截图，问是否可以解决，我看了下问题随即回复：“可以，必须可以”。</p>
                     <p>随后过了2分钟，我的同事杨金龙在群里回复：“已处理”，并上传了最新的截图。问题完美解决，客服的同事也送来了大大的赞，大家都很开心。</p>
                     <p>通过这个小插曲，反映了几个问题：</p>
                     <p>1）客服的同事很辛苦，周六还在解决客户问题，我们作为支撑部门当然需要提供必要的支持，更早、更快的解决客户问题，提升客户体验；</p>
                     <p>2）沟通过程中，问题描述清晰，解决起来也很顺畅，事半功倍；3）部门之间的互相配合，有时也需要互相点赞、互相夸夸对方，促进同事关系，让大家工作起来更加一心。</p>
                    </el-card>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      activeName: ['1'],
      currentIndex: 11,
      count: 0,
    };
  },
  activated() {
    console.log("about-activated");
  },
  methods: {
    changeProject(index) {
      this.currentIndex = index;
    },
    load() {
      this.count += 2
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    }
  }
};
</script>
<style lang="less" scoped>
.info img {
  width: 100%;
  height: 328px;
}

/deep/ .el-collapse {
  border: 1px solid #EBEEF5;
}

/deep/ .el-collapse-item__header {
  padding-left: 10px;
  color: #fff;
  background-color: #0081CF;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.product-list .item {
  cursor: pointer;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #ddd;
}

.product-list .item.active {
  font-weight: 500;
  color: #476CDD;
}
.infinite-list-item{margin-bottom: 10px;}
</style>