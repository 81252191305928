<template>
  <div class="contact">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>联系我们</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>联系我们</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <CompanyMap />
          <div class="row">
            <div class="col-md-3">
              <h2 class="section-title" style="justify-content: left">公司地址</h2>
              <div class="contact-detail" style="margin-top: 20px">
                <address>
                  <p>
                    <span style="font-size: 20px">河南正博电子商务有限公司</span>
                    <br />河南自贸试验区郑州片区（经开）第八大街160号附5号综合保税区
                  </p>
                </address>
              </div>
            </div>
            <div class="col-md-9">
              <h2 class="section-title" style="justify-content: left">联系我们</h2>
              <div class="contact-detail" style="margin-top: 20px">
                <address>
                  <p>电话: 132-5348-2022</p>
                  <p>邮箱: services@zhengboec.com</p>
                </address>
              </div>
              <!-- <form @submit.prevent="saveinfo" class="contact-form">
                <div class="row">
                  <div class="col-md-4">
                    <input v-model="info.name" type="text" placeholder="姓名 *" />
                  </div>
                  <div class="col-md-4">
                    <input v-model="info.phone" type="text" placeholder="电话 *" />
                  </div>
                  <div class="col-md-4">
                    <input v-model="info.email" type="text" placeholder="邮箱 *" />
                  </div>
                </div>
                <textarea
                  v-model="info.content"
                  placeholder="请留下您的宝贵意见"
                  style="color: #000"
                ></textarea>
                <p class="text-right">
                  <input type="submit" value="提交" />
                </p>
              </form> -->
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
import CompanyMap from "@/common/CompanyMap.vue";
export default {
  name: "contact",
  data() {
    return {
      info: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },
    };
  },
  components: { CompanyMap },
  mounted() {
    console.log("contact-mounted");
  },
  activated() {
    console.log("contact-activated");
    this.info = {
      name: "",
      phone: "",
      email: "",
      content: "",
    };
  },
  methods: {
    saveinfo() {
      let { name, phone, email, content } = this.info;
      if (!name || !phone || email) return;
      this.$api
        .doAPI("/savecontact", { info: { name, phone, email, content } })
        .then((res) => {
          if (res.code === 0) {
            this.$swal("您的宝贵意见我们已经收到，感谢您的支持");
          } else {
            if (res.msg) this.$swal(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="less" scoped></style>
