<template>
  <div class="postcode">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>邮编查询</h2>
            <div class="ol">
               <a href="javascript:;" @click="goto('/')">首页</a>
               <span>邮编查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="query">
            <div class="subtitle">邮编查询</div>
            <form class="queryform">
              <div class="row">
                <div class="col-md-4">
                  <label>国家/州代码：</label>
                  <input
                    v-model="code"
                    style="width: 100%; color: #333"
                    placeholder="请输入国家/州代码"
                  />
                </div>
                <div class="col-md-4">
                  <label>城市名称：</label>
                  <input
                    v-model="cityname"
                    style="width: 100%; color: #333"
                    placeholder="请输入城市名称"
                  />
                </div>
                <div class="col-md-4">
                  <label style="height: 22px"></label>
                  <input
                    type="submit"
                    class="button"
                    value="查询"
                    @click.prevent="doSearch"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="result">
            <div class="subtitle" style="border-bottom: 1px solid #ccc">
              查询结果
            </div>
            <div class="row" v-if="zipcode">
              <div class="col-md-3">
                <label>国家/州代码</label>
                <h2>{{ zipcode.code }}</h2>
              </div>
              <div class="col-md-3">
                <label>城市名称</label>
                <h2>{{ zipcode.cityname }}</h2>
              </div>
              <div class="col-md-3">
                <label>邮政编号</label>
                <h2 style="word-wrap:break-word;word-break:normal; ">{{ zipcode.zipcode }}</h2>
              </div>
              <div class="col-md-3">
                <label>是否偏远</label>
                <h2 v-if="zipcode.far === '0'">否</h2>
                <h2 v-if="zipcode.far === '1'">是</h2>
              </div>
            </div>
            <div class="row" v-else-if="msg">
              <div class="col-md-12">
                <label style="color: red">{{ msg }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "PostCode",
  data() {
    return {
      code: "",
      cityname: "",
      zipcode: null,
      fetched: false,
      msg: "",
    };
  },
  activated() {
    console.log("Waybill-activated");
    this.code = "";
    this.cityname = "";
  },
  methods: {
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
    doSearch() {
      this.$api
        .doAPI({
          Action: "web_GetZipCode",
          Data: {
            code: this.code,
            cityname: this.cityname,
          },
        })
        .then((res) => {
          this.fetched = true;
          if (res.code === 0) {
            this.zipcode = res.data.zipcode;
            this.msg = "";
          } else {
            this.msg = res.msg;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  background-color: #e2e2e2;
}
.query {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.query .button {
  padding: 10px 50px;
}
.queryul li {
  list-style: none;
  padding: 5px;
  margin: 10px 0;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  color: #355976;
}
.queryul li.on {
  border-bottom: 1px solid #286ed6;
}
.queryform {
  padding: 0 0 10px 0;
}
.queryform .vxe-pulldown {
  width: 100%;
}
.queryform .bulk {
  margin: 0;
}
.queryform .bulk .col-md-3 {
  padding: 0;
}
.queryform .bulk span.x {
  display: flex;
  height: 34px;
  justify-content: center;
  align-items: flex-end;
}
.queryform .vxe-input {
  width: 100%;
}
.queryform label {
  display: block;
}
p.text-center {
  text-align: center;
}
.subtitle {
  font-size: 35px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.result {
  padding: 20px 0;
}
</style>