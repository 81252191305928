<template>
  <div class="header">
    <header class="site-header">
      <div class="top-header">
        <div class="container" style="display: flex; align-items: center">
          <div class="pull-left" style="flex: 1">
            <a href="javascript:;" class="email">
              <img src="@/assets/images/icon-email.png" class="icon" />services@zhengboec.com
            </a>
            <a href="javascript:;" class="phone">
              <img src="@/assets/images/icon-phone.png" class="icon" />132-5348-2022
            </a>
          </div>
          <div class="right-section pull-right">
            <div class="social-links">
              <a href="javascript:;" @click="gotologin">用户登录</a>
              <a href="javascript:;" @click="goto('/register')">用户注册</a>
            </div>
          </div>
        </div>
      </div>
      <!-- .top-header -->
      <div class="bottom-header">
        <div class="container ct">
          <div class="logo">
            <img src="@/assets/images/logo.png" style="width: 48px; height: 48px" />
            河南正博电子商务有限公司
          </div>
          <div class="main-navigation">
            <button type="button" class="menu-toggle">
              <i class="fa fa-bars"></i>
            </button>
            <ul class="menu">
              <li class="menu-item" v-for="menuitem in menuitems" :key="menuitem.path">
                <a :href="menuitem.path">
                  {{ menuitem.title }}
                </a>
              </li>
            </ul>
            <!-- .menu -->
          </div>
          <!-- .main-navigation -->
          <div class="mobile-navigation"></div>
        </div>
      </div>
    </header>
    <!-- .site-header -->
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      menuitems: [
        { title: "首页", path: "/" },
        { title: "产品与服务", path: "/products" },
        { title: "服务与支持", path: "/services" },
        { title: "集团业务", path: "/business" },
        { title: "新闻中心", path: "/news" },
        { title: "关于我们", path: "/about" },
        { title: "联系我们", path: "/contact" },
      ],
      searchtext: "",
    };
  },
  methods: {
    doSearch() {
      console.log(`[${Math.random()}]doSearch`);
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
    gotologin() {
      this.$utils.downloadBrowser({ href: process.env.CLIENT_LOGIN_URL });
    },
  },
};
</script>
<style lang="less" scoped></style>
