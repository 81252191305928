<template>
  <div class="waybill">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>轨迹查询</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>轨迹查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="query">
            <div class="subtitle">轨迹查询</div>
            <span>每行一个单号，一次最多可以查询40个单号</span>
            <form class="queryform">
              <textarea v-model="tracking_numbers" rows="10" style="width: 100%; color: #333"
                placeholder="每行一个单号，最多可以查询40个单号" />
            </form>
            <p class="text-center">
              <input type="submit" class="button" value="查询" @click="doTrack" :disabled="!tracking_numbers" />
            </p>
          </div>
          <div class="result" v-if="tracklist.length > 0">
            <div class="subtitle" style="border-bottom: 1px solid #ccc">
              查询结果
            </div>
            <vm-collapse>
              <vm-collapse-item v-for="item in tracklist" :key="item.tracking_number">
                <template slot="header">
                  <div style="display:flex;align-items:center;">
                    <span style="font-size:16px;font-weight:bold;">
                      运单号：{{ item.tracking_number }}
                    </span>
                    <p v-if="item.tracks.length > 0" style="margin:0; margin-left: 10px;">
                      <span style="color:#333;font-size:14px;">{{ item.tracks[0].t }}</span>
                      <span style="margin-left:10px;font-weight:bold;">{{ item.tracks[0].c }}</span>
                    </p>
                  </div>
                </template>
                <div class="best-rating__content">
                  <p v-for="titem in item.tracks" :key="titem.uuid" style="margin:0;">
                    <span style="color:#333;font-size:14px;">{{ titem.t }}</span>
                    <span style="margin-left:10px;font-weight:bold;">{{ titem.c }}</span>
                  </p>
                </div>
              </vm-collapse-item>
            </vm-collapse>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "Waybill",
  components: {
  },
  data() {
    return {
      tracking_numbers: "",
      tracklist: [],
    };
  },
  activated() {
    console.log("Waybill-activated");
    this.tracking_numbers = (this.$route.query.code || "").split(',').join('\n');
    if (this.tracking_numbers) {
      this.doTrack();
    }
  },
  watch: {},
  mounted() {
    console.log("home-mounted");
  },
  methods: {
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
    doTrack() {
      if (!this.tracking_numbers) return;
      this.$api.doAPI("/track", { tracking_numbers: this.tracking_numbers, }).then((res) => {
        if (res.code === 0) {
          this.tracklist = res.data.tracklist;
        }
      }).catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  background-color: #e2e2e2;
}

.query {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.query .button {
  padding: 10px 50px;
}

.queryul li {
  list-style: none;
  padding: 5px;
  margin: 10px 0;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  color: #355976;
}

.queryul li.on {
  border-bottom: 1px solid #286ed6;
}

.queryform {
  padding: 0 0 10px 0;
}

.queryform .vxe-pulldown {
  width: 100%;
}

.queryform .bulk {
  margin: 0;
}

.queryform .bulk .col-md-3 {
  padding: 0;
}

.queryform .bulk span.x {
  display: flex;
  height: 34px;
  justify-content: center;
  align-items: flex-end;
}

.queryform .vxe-input {
  width: 100%;
}

.queryform label {
  display: block;
}

p.text-center {
  text-align: center;
}

.subtitle {
  font-size: 35px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.result {
  padding: 20px 0;
}
</style>