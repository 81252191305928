import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
// import EmptyLayout from '@/layouts/EmptyLayout.vue';

import Home from '@/components/home.vue';
import Products from '@/components/products.vue';
import ProductDetail from '@/components/products_detail.vue';
import Services from '@/components/services.vue';
import Business from '@/components/business.vue';
import News from '@/components/news.vue';
import NewsDetail from '@/components/news_detail.vue';
import About from '@/components/about.vue';
import Contact from '@/components/contact.vue';
import Pricequery from '@/components/pricequery.vue';
import Waybill from '@/components/waybill.vue';
import PostCode from '@/components/postcode.vue';
import Register from '@/components/register.vue';
import Login from '@/components/login.vue';
import PriceTest from '@/views/pricetest.vue';
import PriceResult from '@/views/priceresult.vue';
import NotFound from '@/views/404.vue';
import Sign from '@/views/sign.vue';

const routes = [
  //首页
  {
    path: '/',
    component: Home,
    meta: { layout: 'basic-layout', title: "首页" },
  },
  //产品与服务
  {
    path: '/products',
    component: Products,
    meta: { layout: 'basic-layout', title: "产品与服务" },
  },
  //产品服务-详情
  {
    path: '/productdetail',
    component: ProductDetail,
    meta: { layout: 'basic-layout', title: "产品详情" },
  },
  //服务与支持
  {
    path: '/services',
    component: Services,
    meta: { layout: 'basic-layout', title: "服务与支持" },
  },
  //集团业务
  {
    path: '/business',
    component: Business,
    meta: { layout: 'basic-layout', title: "集团业务" },
  },
  //新闻动态
  {
    path: '/news',
    component: News,
    meta: { layout: 'basic-layout', title: "新闻中心" },
  },
  //新闻动态-详情
  {
    path: '/newsdetail',
    component: NewsDetail,
    meta: { layout: 'basic-layout', title: "新闻详情" },
  },
  //关于我们
  {
    path: '/about',
    component: About,
    meta: { layout: 'basic-layout', title: "关于我们" },
  },
  //联系我们
  {
    path: '/contact',
    component: Contact,
    meta: { layout: 'basic-layout', title: "联系我们" },
  },
  //报价查询
  {
    path: '/pricequery',
    component: Pricequery,
    meta: { layout: 'basic-layout', title: "报价查询" },
  },
  //运单查询
  {
    path: '/waybill',
    component: Waybill,
    meta: { layout: 'basic-layout', title: "运单查询" },
  },
  //邮编查询
  {
    path: '/postcode',
    component: PostCode,
    meta: { layout: 'basic-layout', title: "邮编查询" },
  },
  //注册
  {
    path: '/register',
    component: Register,
    meta: { layout: 'basic-layout', title: "用户注册" },
  },
  //登录
  {
    path: '/login',
    component: Login,
    meta: { layout: 'basic-layout', title: "用户登录" },
  },
  //价格测试（公众号）
  {
    path: '/pricetest',
    component: PriceTest,
  },
  {
    path: '/priceresult',
    component: PriceResult,
  },
  {
    path: '/sign',
    component: Sign,
  },
  //404
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new Router({
  mode: 'history',
  routes,
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  next();
});
window._doRouterPush = (path) => router.push({ path });
window._doRouterReplace = (path) => router.replace({ path });

export default router;
