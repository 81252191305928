<template>
  <div class="pricequery">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>报价查询</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>报价查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="query">
            <div class="subtitle">报价查询</div>
            <form class="queryform">
              <div class="row">
                <div class="col-md-4">
                  <label>目的地国家/地区：</label>
                  <CountryRegions @setcountryregion="setcountryregion" :code="code" />
                </div>
                <div class="col-md-4">
                  <label>货物重量：</label>
                  <vxe-input
                    v-model="weight"
                    type="number"
                    placeholder="货物重量"
                    clearable
                  >
                    <template v-slot:suffix>
                      <span>KG</span>
                    </template>
                  </vxe-input>
                </div>
                <div class="col-md-4">
                  <label>货物体积：</label>
                  <div class="row bulk">
                    <div class="col-md-3">
                      <vxe-input v-model="cube_length" type="integer" placeholder="长">
                        <template v-slot:suffix>
                          <span>CM</span>
                        </template>
                      </vxe-input>
                    </div>
                    <div class="col-md-1">
                      <span class="x">X</span>
                    </div>
                    <div class="col-md-3">
                      <vxe-input v-model="cube_width" type="integer" placeholder="宽">
                        <template v-slot:suffix>
                          <span>CM</span>
                        </template>
                      </vxe-input>
                    </div>
                    <div class="col-md-1">
                      <span class="x">X</span>
                    </div>
                    <div class="col-md-3">
                      <vxe-input v-model="cube_height" type="integer" placeholder="高">
                        <template v-slot:suffix>
                          <span>CM</span>
                        </template>
                      </vxe-input>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <p class="text-center">
              <input type="submit" class="button" value="查询" @click="doQuery" />
            </p>
          </div>
          <div class="result">
            <div class="subtitle" style="border-bottom: 1px solid #ccc">
              查询结果
              <span style="font-size: 16px; color: #666; font-weight: 400">
                （注：产品计费以实际下单为准，列表内展示的仅供参考）
              </span>
            </div>
            <div class="table">
              <vxe-table
                border
                align="center"
                highlight-hover-row
                ref="xTable"
                :data="tableData"
                :expand-config="{
                  iconOpen: 'fa fa-minus-square',
                  iconClose: 'fa fa-plus-square',
                }"
                :edit-config="{
                  trigger: 'click',
                  mode: 'cell',
                  icon: 'fa fa-pencil',
                }"
                @edit-closed="changeRate"
              >
                <vxe-table-column field="name" title="渠道"> </vxe-table-column>
                <vxe-table-column field="remark" title="备注"></vxe-table-column>
                <!-- <vxe-table-column field="type" title="货物类型"></vxe-table-column> -->
                <!-- <vxe-table-column field="trackable" title="是否可追踪"></vxe-table-column> -->
                <vxe-table-column
                  field="cube_per_size"
                  title="材积除以数"
                ></vxe-table-column>
                <vxe-table-column field="real_gram" title="重量(Kg)"></vxe-table-column>
                <vxe-table-column
                  field="freight"
                  title="运费(RMB)"
                  sort-type="number"
                  sortable
                ></vxe-table-column>
                <vxe-table-column
                  field="extra_fee"
                  title="杂费(RMB)"
                  sort-type="number"
                  sortable
                ></vxe-table-column>
                <vxe-table-column
                  field="fee_rate"
                  title="折扣%"
                  :edit-render="{
                    name: '$input',
                    props: { type: 'integer', max: 100, min: 1 },
                  }"
                >
                </vxe-table-column>
                <vxe-table-column
                  field="discount_fee"
                  title="折扣价(RMB)"
                  sort-type="number"
                  sortable
                ></vxe-table-column>
              </vxe-table>
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
import Vue from "vue";
import CountryRegions from "@/common/CountryRegions.vue";
import "xe-utils";
import "vxe-table/lib/style.css";
import VXETable from "vxe-table";
Vue.use(VXETable);
import Decimal from "decimal.js";
export default {
  name: "Pricequery",
  data() {
    return {
      code: "",
      countryregion: {
        title: "",
        _id: "",
        name: "",
        name_en: "",
        code: "",
      },
      weight: "",
      cube_length: "",
      cube_width: "",
      cube_height: "",
      tableData: [],
    };
  },
  components: {
    CountryRegions,
  },
  activated() {
    console.log("Pricequery-activated");
    let { code, weight, cube_length, cube_width, cube_height } = this.$route.query;
    this.code = code || "";
    this.weight = weight || "";
    this.cube_length = cube_length || "";
    this.cube_width = cube_width || "";
    this.cube_height = cube_height || "";
    if (code && weight) this.doQuery();
  },
  methods: {
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
    setcountryregion(data) {
      this.code = data.code;
      this.countryregion = data;
    },
    doQuery() {
      console.log("PriceQuery-doQuery");
      this.$api
        .doAPI("/queryprice", {
          code: this.code,
          weight: this.weight,
          length: this.cube_length,
          width: this.cube_width,
          height: this.cube_height,
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.list;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeRate(e) {
      console.log(e.rowIndex, e.row);
      new Decimal("1");
      let tableData = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let tableitem = { ...this.tableData[i] };
        if (i === e.rowIndex) {
          tableitem.discount_fee = new Decimal(tableitem.freight)
            .plus(new Decimal(tableitem.extra_fee))
            .times(Number(e.row.fee_rate))
            .div(100)
            .toFixed(2);
        }
        tableData.push({ ...tableitem });
      }
      this.tableData = tableData;
      this.$refs.xTable.reloadData(tableData);
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  background-color: #e2e2e2;
}
.query {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.query .button {
  padding: 10px 50px;
}
.queryform {
  padding: 20px 0 10px 0;
}
.queryform .vxe-pulldown {
  width: 100%;
}
.queryform .bulk {
  margin: 0;
}
.queryform .bulk .col-md-3 {
  padding: 0;
}
.queryform .bulk span.x {
  display: flex;
  height: 34px;
  justify-content: center;
  align-items: flex-end;
}
.queryform .vxe-input {
  width: 100%;
}
.queryform label {
  display: block;
}
p.text-center {
  text-align: center;
}
.subtitle {
  font-size: 35px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.result {
  padding: 20px 0;
}
.vxe-input {
  font-size: 16px;
  height: 48px;
}
.row.bulk {
  margin: 0;
}
.bulk .col-md-3 {
  padding: 0;
}
.query span.x {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
  font-size: 20px;
  color: #c0c4cc;
}
.countrysbox ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  border-bottom: 1px solid #ddd;
}
.countrysbox {
  padding: 10px;
}
.countrysbox ul li {
  list-style: none;
  padding: 5px 0;
  font-size: 14px;
  cursor: pointer;
  display: inline;
  color: #355976;
}
.countrysbox ul li.active {
  border-bottom: 1px solid #286ed6;
}
.countrysbox .countrygroup {
  margin-top: 10px;
}
.countrysbox .countrygroup span {
  position: relative;
  width: 20%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: white;
  padding: 5px;
  right: auto;
  top: auto;
  word-wrap: break-word;
  color: #4a4a4a;
  font-size: 12px;
}
.vxe-pulldown--panel {
  border: 1px solid #ddd;
}
</style>
