<template>
  <div class="pricetest">
    <div class="header">
      <img src="@/assets/images/logo2.jpeg" style="width: 128px; height: 128px" />
    </div>
    <div class="content">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="目的地：" prop="code">
          <el-select v-model="form.code" filterable :filter-method="filterMethod" placeholder="请选择目的地"
            style="width: 100%">
            <el-option v-for="region in options" :key="region.code"
              :label="`[${region.code}]${region.name} ${region.name_en}`" :value="region.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重量(KG)：" prop="weight">
          <el-input v-model="form.weight" type="number" placeholder="重量(KG)"></el-input>
        </el-form-item>
        <el-form-item label="体积：">
          <div class="flex">
            <el-input v-model="form.cute_length" type="number" placeholder="长(CM)"></el-input>
            <el-input v-model="form.cute_width" type="number" placeholder="宽(CM)"></el-input>
            <el-input v-model="form.cute_height" type="number" placeholder="高(CM)"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" round style="width: 200px" @click="onSubmit">
            确认
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="message">
      温馨提示：产品计费请以实际下单为准，列表内的价格仅供参考，详情请联系您的客户经理。
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceTest",
  data() {
    return {
      regions: [],
      rules: {
        code: [{ required: true, message: "请选择目的地", trigger: "change" }],
        weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
      },
      form: {
        code: "",
        weight: "",
        cute_length: "",
        cute_width: "",
        cute_height: "",
      },
      query: "",
    };
  },
  computed: {
    options() {
      if (this.query) {
        return this.regions.filter((n) => {
          return n.code.indexOf(this.query) > -1 || n.name.indexOf(this.query) > -1;
        });
      } else {
        return this.regions;
      }
    },
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$api.doAPI("/countryregions2").then((res) => {
        if (res.code === 0) {
          console.log(res.data);
          this.regions = res.data.regions;
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    filterMethod(query) {
      this.query = query;
    },
    onSubmit() {
      console.log("submit!");
      this.$refs.form.validate((valid) => {
        if (valid) {
          let region = this.regions.filter((n) => n.code === this.form.code)[0];
          console.log(region);
          this.$router.push({
            path: "/priceresult",
            query: { ...this.form, name: region.name },
          });
        } else {
          console.log("error submit!!");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  border-bottom: 10px solid #eee;
}

.content {
  padding: 20px 10px;

  .flex {
    display: flex;
  }
}

.message {
  color: #e6a23c;
  background-color: #eee;
  padding: 20px 10px;
}
</style>
