<template>
  <div class="products_detail">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>{{ product.cat }}</h2>
            <div class="ol">
               <a href="javascript:;" @click="goto('/')">首页</a>
                <a href="javascript:;" @click="goto('/products')">产品服务</a>
               <span>{{ product.cat }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container content">
          <div class="subtitle">{{ product.name }}</div>
          <div class="group">
            <div class="title">产品概述</div>
            <div class="info">
              {{ product.desc }}
            </div>
          </div>
          <div class="group">
            <div class="title">产品介绍</div>
            <div class="info">
              <table class="tbl">
                <tbody>
                  <tr>
                    <th>产品名称</th>
                    <td>{{ product.name }}</td>
                  </tr>
                  <tr>
                    <th>参考时效</th>
                    <td>{{ product.timeliness }}</td>
                  </tr>
                  <tr>
                    <th>走货属性</th>
                    <td>{{ product.product_attr }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="group">
            <div class="title">价格详情</div>
            <div class="info" v-html="product.price_detail"></div>
          </div>
          <div class="group">
            <div class="title">产品优势</div>
            <div class="info">
              {{ product.superiority }}
            </div>
          </div>
          <div class="group">
            <div class="title">服务热线</div>
            <div class="info">
              {{ product.hotline }}
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
export default {
  name: "ProductDetail",
  data() {
    return {
      product: {},
    };
  },
  activated() {
    console.log("ProductDetail-activated");
    this.product = {};
    this.getdata();
  },
  methods: {
    getdata() {
      this.$api
        .doAPI({
          Action: "web_ProductDetail",
          Data: {
            productId: this.$route.query.id,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.product = res.data.product;
          } else {
            console.log(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  background-color: #e2e2e2;
}
.content {
  background-color: #fff;
  border-radius: 10px;
}
.content .subtitle {
  font-size: 35px;
  font-weight: 600;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #ddd;
}
.content .title {
  position: relative;
  font-size: 25px;
  font-weight: 400;
  padding: 20px 0 10px 0;
}
.content .title::after {
  content: "";
  width: 40px;
  height: 4px;
  background-color: #fa9600;
  position: absolute;
  left: 0;
  bottom: 0;
}
.content .info {
  padding: 20px 0;
}
.content .info .tbl {
  width: 100%;
}
.content .info .tbl tr {
  border-bottom: 0.5px solid #b4b4b4;
  text-align: left;
}
.content .info .tbl th {
  width: 200px;
}
.content .info .tbl th,
td {
  color: #333;
  padding: 20px 0px;
}
</style>