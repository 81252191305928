<template>
  <div class="news">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>新闻动态</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>新闻动态</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="row">
            <div class="col-md-2 product-list" style="padding: 0">
              <el-collapse v-model="activeName">
                <el-collapse-item title="新闻动态" name="1">
                  <div
                    :class="currentIndex == 11 ? 'item active' : 'item'"
                    @click="changeProject(11)"
                  >
                    正博新闻
                  </div>
                  <div
                    :class="currentIndex == 12 ? 'item active' : 'item'"
                    @click="changeProject(12)"
                  >
                    重要公告
                  </div>
                  <div
                    :class="currentIndex == 13 ? 'item active' : 'item'"
                    @click="changeProject(13)"
                  >
                    行业新闻
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="col-md-10">
              <div class="mcontent">
                <div class="list row">
                  <div
                    v-for="item in list"
                    :key="item._id"
                    class="item col-md-4"
                    @click="shownews(item._id)"
                  >
                    <div class="img">
                      <img :src="item.cover" />
                    </div>
                    <div class="info">
                      <div class="time">
                        <h3>{{ item.createdAt | dateTime("YYYY") }}</h3>
                        <p>{{ item.createdAt | dateTime("MM-DD") }}</p>
                      </div>
                      <div class="summary">
                        <div class="lf">
                          <p class="title">{{ item.title }}</p>
                          <p class="ex">{{ item.summary }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="pagination"
                  v-if="length > 0"
                  style="display: flex; justify-content: center; align-items: center"
                >
                  <a v-if="page > 1" href="javascript:;" @click="page--"> ← </a>
                  <span>{{ page }}/{{ length }}</span>
                  <a v-if="page < length" href="javascript:;" @click="page++"> → </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
export default {
  name: "news",
  data() {
    return {
      cat: "",
      activeName: ["1"],
      currentIndex: 11,
      cats: [
        { text: "全部分类", value: "" },
        { text: "公司动态", value: "公司动态" },
        { text: "重要公告", value: "重要公告" },
        { text: "行业新闻", value: "行业新闻" },
      ],
      page: 1,
      limit: 8,
      list: [],
      length: 0,
    };
  },
  activated() {
    console.log("News-activated");
    if (this.cat !== "") {
      this.cat = "";
    } else {
      this.getdata();
    }
  },
  methods: {
    changeProject(index) {
      this.currentIndex = index;
    },
    shownews(newsId) {
      this.$router.push({ path: "/newsdetail", query: { id: newsId } });
    },
    getdata() {
      this.list = [
        {
          _id: "d306933cd2c649559787ffc970edce1f",
          title: "关于正博部分产品调整的通知",
          summary: "关于正博部分产品调整的通知，部分产品降价",
          cover: require("@/assets/images/news-2.jpg"),
          createdAt: "2022-06-17 09:23:12",
        },
        {
          _id: "38eb86df40fc4f14b9facfe31b7649c1",
          title: "关于E邮宝郑州（普+特）-敦煌上线的通知",
          summary:
            "正博E邮宝产品和敦煌网合作，在敦煌网上线了一条优质线路——E邮宝郑州（普+特）-DF",
          cover: require("@/assets/images/news-3.jpg"),
          createdAt: "2022-06-02 16:25:02",
        },
        {
          _id: "e1d1466dddbc43c5ae29d71b64197549",
          title: "河南省商贸流通业第一批96家“四保”企业 | 名单",
          summary: "河南省商贸流通业第一批96家“四保”企业 | 名单，河南正博上榜",
          cover: require("@/assets/images/news-1.jpg"),
          createdAt: "2022-05-31 21:59:09",
        },
      ];
      this.length = this.list.length;
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
  },
  watch: {
    cat() {
      if (this.page > 1) this.page = 1;
      else this.getdata();
    },
    page() {
      this.getdata();
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  .row {
    margin: 0 -5px;
  }
}

.list {
  margin-top: 30px;
}

.list .item {
  padding-left: 10px;
  padding-right: 0;
  padding-bottom: 10px;
  cursor: pointer;
}

.list .item img {
  width: 100%;
  height: 228px;
}

.list .item .info {
  display: flex;
}

.list .item .info p {
  margin: 0;
  line-height: 1.5;
}

.list .item .info .time {
  border-right: 1px solid #999;
  padding-right: 5px;
}

.list .item .info .time h2,
h3 {
  margin: 0;
}

.list .item .info .summary {
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.list .item .info .summary .title {
  color: #000;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.list .item .info .summary .ex {
  color: #999;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

/deep/ .el-collapse {
  border: 1px solid #ebeef5;
}

/deep/ .el-collapse-item__header {
  padding-left: 10px;
  color: #fff;
  background-color: #0081cf;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.product-list .item {
  cursor: pointer;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #ddd;
}

.product-list .item.active {
  font-weight: 500;
  color: #476cdd;
}
</style>
