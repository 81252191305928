import Vue from 'vue'
import App from './App.vue'
import router from './router'

import BasicLayout from './layouts/BasicLayout'
import EmptyLayout from './layouts/EmptyLayout'
Vue.component('basic-layout', BasicLayout);
Vue.component('empty-layout', EmptyLayout);
import api from './request'
Vue.prototype.$api = api;
import utils from './utils';
Vue.prototype.$utils = utils;
import axios from 'axios'
Vue.prototype.$axios = axios;
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.filter('dateFormat', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('dateTime', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('fromNow', function (date) {
  return utils.fromNow(date);
});
import { Collapse, CollapseItem, InfiniteScroll,Card, Form,FormItem, Input,Button,Select,Option,Table,TableColumn,} from 'element-ui';
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(InfiniteScroll);
Vue.use(Card);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);

import animated from 'animate.css'
Vue.use(animated)
import VmCollapse from 'vue-multiple-collapse'
Vue.use(VmCollapse)
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
