<template>
  <div class="register">
    <main class="main-content">
      <div class="container">
        <div class="title">
          <h2>用户注册</h2>
        </div>
        <form>
          <div class="margin">
            <div class="row">
              <input
                v-model="memberinfo.username"
                type="text"
                class="input"
                placeholder="用户名"
                @input="checkinput($event, 'username')"
                autocomplete="off"
              /><span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.username !== ''">
                  {{ rules.username[errors.username] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row">
              <input
                v-model="memberinfo.mobile"
                type="number"
                class="input"
                placeholder="手机号"
                @input="checkinput($event, 'mobile')"
                autocomplete="off"
              /><span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.mobile !== ''">
                  {{ rules.mobile[errors.mobile] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row">
              <input
                v-model="memberinfo.email"
                type="email"
                class="input"
                placeholder="邮箱"
                @input="checkinput($event, 'email')"
                autocomplete="off"
              /><span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.email !== ''">
                  {{ rules.email[errors.email] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row">
              <input
                v-model="memberinfo.password"
                type="password"
                class="input"
                placeholder="密码"
                @input="checkinput($event, 'password')"
                autocomplete="new-password"
              /><span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.password !== ''">
                  {{ rules.password[errors.password] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row">
              <input
                v-model="memberinfo.password2"
                type="password"
                class="input"
                placeholder="确认密码"
                @input="checkinput($event, 'password2')"
                autocomplete="new-password"
              /><span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.password2 !== ''">
                  {{ rules.password2[errors.password2] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row valide">
              <input
                v-model="memberinfo.svgcode"
                type="number"
                class="input"
                placeholder="图形验证码"
                @input="checkinput($event, 'svgcode')"
                autocomplete="off"
              />
              <a href="javascript:;" @click="refreshdata" class="svg">
                <img :src="img" />
              </a>
              <span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.svgcode !== ''">
                  {{ rules.svgcode[errors.svgcode] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="margin">
            <div class="row valide">
              <input
                v-model="memberinfo.verifycode"
                type="text"
                class="input"
                placeholder="短信验证码"
                @input="checkinput($event, 'verifycode')"
                autocomplete="off"
              />
              <a v-if="sending" href="javascript:;" class="button disabled">
                {{ sendtext }}
              </a>
              <a v-else href="javascript:;" class="button" @click="sendcode">
                {{ sendtext }}
              </a>
              <span class="red">*</span>
            </div>
            <div class="error">
              <transition
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <span class="red" v-if="errors.verifycode !== ''">
                  {{ rules.verifycode[errors.verifycode] }}
                </span>
              </transition>
            </div>
          </div>
          <div class="row margin disclaimer" style="justify-content: left">
            <label class="checkbox-inline">
              <input
                v-model="agreed"
                type="checkbox"
                style="width: 20px"
                @change="checkinput($event, 'agreed')"
              />
              我已阅读并同意
              <a href="javascript:;" @click="showlicense = true">
                《正博用户注册协议》
              </a>
            </label>
          </div>
        </form>
        <div class="margin">
          <div class="error">
            <transition
              enter-active-class="animate__animated animate__fadeInDown"
              leave-active-class="animate__animated animate__fadeOutUp"
            >
              <span class="red" v-if="errors.agreed !== ''">
                {{ rules.agreed[errors.agreed] }}
              </span>
            </transition>
          </div>
          <a href="javascript:;" class="button regbtn" @click="doReg"> 立即注册 </a>
        </div>
      </div>
    </main>
    <vxe-modal
      v-model="showlicense"
      title="《正博用户注册协议》"
      show-zoom
      resize
      remember
      fullscreen
    >
      <template v-slot>
        <div v-html="license"></div>
        <p style="text-align: center">
          <a v-if="agreed" href="javascript:;" @click="showlicense = false"> 关闭 </a>
          <a
            v-else
            href="javascript:;"
            class="button regbtn"
            @click="
              showlicense = false;
              agreed = true;
              checkrule('agreed', true);
            "
          >
            我同意
          </a>
        </p>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import Vue from "vue";
import VXETable from "vxe-table";
Vue.use(VXETable);
import _ from "underscore";
export default {
  name: "register",
  data() {
    return {
      agreed: false,
      uuid: "",
      img: "",
      license: "",
      showlicense: false,
      sending: false,
      sendleft: 120,
      sendtext: "获取验证码",
      memberinfo: {
        username: "",
        mobile: "",
        email: "",
        password: "",
        password2: "",
        svgcode: "",
        verifycode: "",
      },
      errors: {
        username: "",
        mobile: "",
        email: "",
        password: "",
        password2: "",
        svgcode: "",
        verifycode: "",
        agreed: "",
      },
      rules: {
        username: {
          reg: /^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{6,12}$/,
          empty: "请输入用户名",
          msg: "用户名为6~12位字母、数字或下划线其中两项组合",
        },
        mobile: {
          reg: /^1[3456789]\d{9}$/,
          empty: "请输入手机号",
          msg: "手机号格式错误",
        },
        email: {
          reg: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          empty: "请输入邮箱",
          msg: "邮箱地址格式错误",
        },
        password: {
          reg: /^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,16}$/,
          empty: "请输入密码",
          msg: "密码为8~16位数字、字母或下划线其中两项组合",
        },
        password2: {
          empty: "请确认密码",
          msg: "两次输入的密码不匹配",
        },
        svgcode: {
          empty: "请输入图形验证码",
        },
        verifycode: {
          empty: "请输入短信验证码",
        },
        agreed: {
          empty: "请阅读服务协议",
        },
      },
    };
  },
  activated() {
    console.log("register-activated");
    this.reset();
  },
  methods: {
    refreshdata() {
      this.$api
        .doAPI("/authcode")
        .then((res) => {
          console.log("authcode", res);
          this.uuid = res.uuid;
          this.img = res.img;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkrule(key, value) {
      if (!value) return (this.errors[key] = "empty");
      else if (this.rules[key].reg && !this.rules[key].reg.test(value)) {
        return (this.errors[key] = "msg");
      } else if (
        key === "password2" &&
        this.memberinfo.password2 !== this.memberinfo.password
      ) {
        return (this.errors[key] = "msg");
      }
      return (this.errors[key] = "");
    },
    checkinput(e, key) {
      let value = e.currentTarget.value;
      if (key === "agreed") value = this.agreed;
      console.log(key, value);
      this.checkrule(key, value);
    },
    checkmemberinfo() {
      let keys = _.keys(this.memberinfo);
      for (let i = 0; i < keys.length; i++) {
        this.checkrule(keys[i], this.memberinfo[keys[i]]);
      }
      this.checkrule("agreed", this.agreed);
      let errors = _.uniq(_.values(this.errors));
      console.log(errors);
      return errors.length === 1 && errors[0] === "";
    },
    countdown(left) {
      this.sendtext = `${left}秒后获取`;
      window._zb_web_timer_ = setTimeout(() => {
        this.countdown(--this.sendleft);
        if (this.sendleft <= 0) {
          this.sending = false;
          this.sendtext = `获取验证码`;
          this.sendleft = 120;
          clearTimeout(window._zb_web_timer_);
        }
      }, 1000);
    },
    sendcode() {
      if (this.sending) return;
      // this.sending = true;
      // this.countdown(--this.sendleft);
      if (!this.checkrule("mobile", this.memberinfo.mobile)) {
        this.$api
          .doAPI("/sendsms", { uuid: this.uuid, mobile: this.memberinfo.mobile })
          .then((res) => {
            if (res.code === 0) {
              this.sending = true;
              this.countdown(this.sendleft--);
              console.log(res.data);
            } else {
              this.$swal.fire({
                title: res.msg,
                icon: "error",
                confirmButtonText: "关闭",
                confirmButtonColor: "#f27474",
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    reset() {
      this.refreshdata();
      this.uuid = "";
      this.img = "";
      this.memberinfo = {
        username: "",
        mobile: "",
        email: "",
        password: "",
        password2: "",
        svgcode: "",
        verifycode: "",
      };
    },
    doReg() {
      // this.$swal.fire({
      //   title: "注册成功",
      //   icon: "success",
      //   confirmButtonText: "确定",
      //   confirmButtonColor: "rgba(165,220,134,1)",
      // });
      // this.$swal.fire({
      //   title: "注册失败",
      //   icon: "error",
      //   confirmButtonText: "关闭",
      //   confirmButtonColor: "#f27474",
      // });
      if (this.checkmemberinfo()) {
        this.$api
          .doAPI("/register", { memberinfo: this.memberinfo, uuid: this.uuid })
          .then((res) => {
            if (res.code === 0) {
              console.log(res);
              this.$swal.fire({
                title: "注册成功",
                icon: "success",
                confirmButtonText: "确定",
                confirmButtonColor: "rgba(165,220,134,1)",
              });
              this.reset();
            } else {
              this.$swal.fire({
                title: res.msg,
                icon: "error",
                confirmButtonText: "关闭",
                confirmButtonColor: "#f27474",
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding: 20px;
}
.container .row {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .margin {
  margin: 0;
}
.error {
  height: 20px;
  text-align: left;
}
.container .red {
  color: red;
  margin-left: 5px;
}
.container input {
  width: 100%;
}
.container .regbtn {
  width: 100%;
}
.container a.disabled {
  background: rgba(15, 117, 189, 0.5);
  cursor: not-allowed;
}
.container .row.valide {
  display: flex;
}
.container .row.valide .input {
  flex: 4;
}
.container .row.valide .button {
  flex: 1;
  margin-left: 5px;
}
.container .row.disclaimer {
  display: flex;
}
.svg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
}
</style>
