<template>
  <div class="products">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>产品服务</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>产品服务</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="row">
            <div class="col-md-2 product-list" style="padding:0;">
              <el-collapse v-model="activeName">
                <el-collapse-item title="中邮产品" name="1">
                  <div :class="currentIndex == 11 ? 'item active' : 'item'" @click="changeProject(11)">E邮宝</div>
                  <div :class="currentIndex == 12 ? 'item active' : 'item'" @click="changeProject(12)">EMS</div>
                  <div :class="currentIndex == 13 ? 'item active' : 'item'" @click="changeProject(13)">小包</div>
                  <div :class="currentIndex == 14 ? 'item active' : 'item'" @click="changeProject(14)">国际包裹</div>
                </el-collapse-item>
                <el-collapse-item title="正博专线产品" name="2">
                  <div :class="currentIndex == 21 ? 'item active' : 'item'" @click="changeProject(21)">正博普货渠道</div>
                  <div :class="currentIndex == 22 ? 'item active' : 'item'" @click="changeProject(22)">FBA产品</div>
                  <div :class="currentIndex == 23 ? 'item active' : 'item'" @click="changeProject(23)">正博特货渠道</div>
                </el-collapse-item>
                <el-collapse-item title="报关服务" name="3">
                  <div :class="currentIndex == 31 ? 'item active' : 'item'" @click="changeProject(31)">9610报关</div>
                  <div :class="currentIndex == 32 ? 'item active' : 'item'" @click="changeProject(32)">南昌报关</div>
                  <div :class="currentIndex == 33 ? 'item active' : 'item'" @click="changeProject(33)">商业进口快件</div>
                </el-collapse-item>
                <el-collapse-item title="一键代发" name="4">
                  <div :class="currentIndex == 41 ? 'item active' : 'item'" @click="changeProject(41)">智能仓储</div>
                  <div :class="currentIndex == 42 ? 'item active' : 'item'" @click="changeProject(42)">一键代发</div>
                  <div :class="currentIndex == 43 ? 'item active' : 'item'" @click="changeProject(43)">仓库地址</div>
                </el-collapse-item>
                <el-collapse-item title="集运服务" name="5">
                  <div :class="currentIndex == 51 ? 'item active' : 'item'" @click="changeProject(51)">一键下单</div>
                  <div :class="currentIndex == 52 ? 'item active' : 'item'" @click="changeProject(52)">轨迹查询</div>
                  <div :class="currentIndex == 53 ? 'item active' : 'item'" @click="changeProject(53)">服务协议</div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="col-md-10">
              <div class="mcontent" v-if="currentIndex === 11">
                <!-- <img src="https://static.dfsupplychain.com/public/zhengbo/website/EUB02.png" width="100%"/>
                  <img src="https://static.dfsupplychain.com/public/zhengbo/website/EUB01.png" width="100%"/> -->
              </div>
              <div class="mcontent" v-if="currentIndex === 12">
                <!-- <img src="https://static.dfsupplychain.com/public/zhengbo/website/EMS02.png" width="100%"/>
                    <img src="https://static.dfsupplychain.com/public/zhengbo/website/EMS01.png" width="100%"/> -->
              </div>
              <div class="mcontent" v-if="currentIndex === 13">
                <!-- <img src="https://static.dfsupplychain.com/public/zhengbo/website/002.png" width="100%"/> -->
              </div>
              <div class="mcontent" v-if="currentIndex === 14">
                <!-- <img src="https://static.dfsupplychain.com/public/zhengbo/website/001.png" width="100%"/> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
export default {
  name: "Products",
  data() {
    return {
      currentIndex: 11,
      activeName: ['1', '2', '3'],
      page: 1,
      limit: 6,
      list: [],
      length: 0,
    };
  },
  activated() {
    console.log("Products-activated");
    this.cat = this.$route.query.cat
      ? decodeURIComponent(this.$route.query.cat)
      : "跨境专线";
    this.getdata();
  },
  methods: {
    changeProject(index) {
      this.currentIndex = index;
      if (index === 52) {
        this.$router.push({ path: "/waybill" })
      }
    },
    showproduct(productId) {
      this.$router.push({ path: "/productdetail", query: { id: productId } });
    },
    getdata() {
      // this.$api
      //   .doAPI({
      //     Action: "web_ProductData",
      //     Data: {
      //       page: this.page,
      //       limit: this.limit,
      //       cat: this.cat,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.list = res.data.list;
      //       this.length = res.data.length;
      //       if (this.length > 0 && this.page > this.length) this.page--;
      //     } else {
      //       console.log(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
  },
  watch: {
    cat() {
      if (this.page > 1) this.page = 1;
      else this.getdata();
    },
    page() {
      this.getdata();
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  .row {
    margin: 0 -4px;
  }
}

.list {
  margin-top: 30px;
}

.list .item {
  padding-left: 10px;
  padding-right: 0;
  padding-bottom: 10px;
  cursor: pointer;
}

.list .item img {
  width: 100%;
  height: 228px;
}

.list .item .info {
  display: flex;
  padding: 20px;
  background: #fff;
}

.list .item .info p {
  margin: 0;
  line-height: 1.5;
}

.list .item .info .time {
  border-right: 1px solid #999;
  padding-right: 5px;
}

.list .item .info .time h2,
h3 {
  margin: 0;
}

.list .item .info .summary {
  padding-left: 5px;
  flex: 1;
}

.list .item .info .summary .title {
  color: #000;
  font-size: 16px;
}

.list .item .info .summary .ex {
  color: #999;
  font-size: 12px;
}

/deep/ .el-collapse {
  border: 1px solid #EBEEF5;
}

/deep/ .el-collapse-item__header {
  padding-left: 10px;
  color: #fff;
  background-color: #0081CF;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.product-list .item {
  cursor: pointer;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #ddd;
}

.product-list .item.active {
  font-weight: 500;
  color: #476CDD;
}
</style>