<template>
  <div class="home">
    <div class="hero hero-slider">
      <div v-if="showquery" class="container query">
        <div class="slide-content">
          <div class="switch">
            <div class="item price" @click="switchClick('price')">
              <img src="@/assets/images/price.png" />报价查询
            </div>
            <div class="item waybill" @click="switchClick('waybill')">
              <img src="@/assets/images/waybill.png" />轨迹查询
            </div>
          </div>
          <div class="content price" v-if="queryIndex == 'price'">
            <form>
              <CountryRegions @setcountryregion="setcountryregion" />
              <vxe-input v-model="weight" type="number" class="input" placeholder="货物重量" clearable>
                <template v-slot:suffix>
                  <span>KG</span>
                </template>
              </vxe-input>
              <div class="row bulk">
                <div class="col-md-3">
                  <vxe-input v-model="cube_length" type="integer" class="input" placeholder="长">
                    <template v-slot:suffix>
                      <span>CM</span>
                    </template>
                  </vxe-input>
                </div>
                <div class="col-md-1">
                  <span class="x">X</span>
                </div>
                <div class="col-md-3">
                  <vxe-input v-model="cube_width" type="integer" class="input" placeholder="宽">
                    <template v-slot:suffix>
                      <span>CM</span>
                    </template>
                  </vxe-input>
                </div>
                <div class="col-md-1">
                  <span class="x">X</span>
                </div>
                <div class="col-md-3">
                  <vxe-input v-model="cube_height" type="integer" class="input" placeholder="高">
                    <template v-slot:suffix>
                      <span>CM</span>
                    </template>
                  </vxe-input>
                </div>
              </div>
            </form>
            <a href="javascript:;" class="button" @click="doQuery">查询</a>
          </div>
          <div class="content waybill" v-if="queryIndex == 'waybill'">
            <form>
              <textarea v-model="track_codes" rows="10" class="input" placeholder="每行一个单号，一次最多可以查询40个单号" />
            </form>
            <a href="javascript:;" class="button1" @click="doTrack">查询</a>
          </div>
        </div>
      </div>
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide
          :style="`background-image:url('https://static.dfsupplychain.com/public/zhengbo/website/banner-1.png')`">
        </swiper-slide>
        <swiper-slide
          :style="`background-image:url('https://static.dfsupplychain.com/public/zhengbo/website/banner-2.png')`">
        </swiper-slide>
        <swiper-slide
          :style="`background-image:url('https://static.dfsupplychain.com/public/zhengbo/website/banner-3.png')`">
        </swiper-slide>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>
    <!-- .hero-slider -->
    <main class="main-content">
      <!-- .fullwidth-block -->
      <div class="fullwidth-block products">
        <div class="container" style="background: #fff; padding: 0">
          <div class="section-title">
            <div class="line"></div>
            <div class="txt">
              <span class="orange">产品</span>与<span class="orange">服务</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="section-subtitle">Products and Services</div>
          <div class="types">
            <div :class="currentIndex == 0 ? 'item active' : 'item'" @click="switchType(0)">
              <div class="head-title">中邮产品</div>
              <div class="sub-title">EUB、EMS、国际小包</div>
            </div>
            <div :class="currentIndex == 1 ? 'item active' : 'item'" @click="switchType(1)">
              <div class="head-title">专线产品</div>
              <div class="sub-title">普货、FBA产品、特货</div>
            </div>
            <div :class="currentIndex == 2 ? 'item active' : 'item'" @click="switchType(2)">
              <div class="head-title">报关类产品</div>
              <div class="sub-title">9610、南昌报关、进口快件</div>
            </div>
            <div :class="currentIndex == 3 ? 'item active' : 'item'" @click="switchType(3)">
              <div class="head-title">一件代发</div>
              <div class="sub-title">智能仓储、智能分拣、放心售后</div>
            </div>
            <div :class="currentIndex == 4 ? 'item active' : 'item'" @click="switchType(4)">
              <div class="head-title">集运服务</div>
              <div class="sub-title">一键下单、智能打包、安全时效</div>
            </div>
          </div>
          <div class="pscontent" v-if="currentIndex === 0">
            <div class="row">
              <div class="col-md-6">
                <div class="message">
                  <p class="tt">
                    依托万国邮政联盟（UPU）,整合目的国邮政资源，提供安全、稳定、高效的中邮产品一体化服务。
                  </p>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />EUB
                      </div>
                      <div class="info">价格灵活、时效稳定、服务保障</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />小包
                      </div>
                      <div class="info">时效稳定、服务保障、价格最优</div>
                    </div>
                  </div>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />EMS
                      </div>
                      <div class="info">时效优先、服务保障、欧洲时效快</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />国际包裹
                      </div>
                      <div class="info">航空、SAL、水陆路、重件优惠</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cover product1"></div>
              </div>
            </div>
          </div>
          <div class="pscontent" v-if="currentIndex === 1">
            <div class="row">
              <div class="col-md-6">
                <div class="message">
                  <p class="tt">
                    正博专线打通多个国家的专线运输渠道，节约国际物流运输成本，同时保证时效。
                  </p>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />普货
                      </div>
                      <div class="info">单独美线1-30公斤以及美加专线</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />FBA产品
                      </div>
                      <div class="info">20公斤以上重货</div>
                    </div>
                  </div>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />特货
                      </div>
                      <div class="info">时效稳定、服务保障、重货，可到27国</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cover product2"></div>
              </div>
            </div>
          </div>
          <div class="pscontent" v-if="currentIndex === 2">
            <div class="row">
              <div class="col-md-6">
                <div class="message">
                  <p class="tt">
                    全国一线港口.机场设立报关报检、仓储、物流、海关现场、业务、客服、数据处理、车辆货运等多位一体的业务流程部门，服务与全球跨境电商贸易。
                  </p>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />9610报关
                      </div>
                      <div class="info">长期稳定、操作方便</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />南昌报关
                      </div>
                      <div class="info">报关渠道稳定</div>
                    </div>
                  </div>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />进口商业快件
                      </div>
                      <div class="info">全程大方操作、清关有保障</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />郑州进口
                      </div>
                      <div class="info">清关稳定</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cover product3"></div>
              </div>
            </div>
          </div>
          <div class="pscontent" v-if="currentIndex === 3">
            <div class="row">
              <div class="col-md-6">
                <div class="message">
                  <p class="tt">
                    专业的运营管理团队、操作熟练的员工分拣打包，和成本更低的快递物流，省钱、省事、省心！
                  </p>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />智能仓储
                      </div>
                      <div class="info">智能化仓储服务，安全高效</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />智能分拣
                      </div>
                      <div class="info">智能系统精准定位商品库位</div>
                    </div>
                  </div>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />快递物流
                      </div>
                      <div class="info">时效稳定、服务保障、价格最优的物流渠道</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />售后服务
                      </div>
                      <div class="info">24小时全程客服跟踪服务，放心、贴心、舒心</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cover product4"></div>
              </div>
            </div>
          </div>
          <div class="pscontent" v-if="currentIndex === 4">
            <div class="row">
              <div class="col-md-6">
                <div class="message">
                  <p class="tt">
                    注册“卿稷速递”会员，海淘购物时填写“卿稷速递”集运仓库地址，通过“卿稷速递”小程序下单，仓库收到包裹完成打包转运。
                  </p>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />一键下单
                      </div>
                      <div class="info">小程序一键下单，简单易用</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />高效便捷
                      </div>
                      <div class="info">物流渠道多、时效稳定、专业的清关团队</div>
                    </div>
                  </div>
                  <div class="row list">
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />价格灵活
                      </div>
                      <div class="info">清关限时免费、下单即送优惠券、价格最优</div>
                    </div>
                    <div class="col-md-6">
                      <div class="title">
                        <img src="@/assets/images/products.png"
                          style="width: 36px; height: 36px; vertical-align: bottom" />全程追踪
                      </div>
                      <div class="info">包裹进度微信消息实时推送，客服24小时为您服务</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cover product5"></div>
              </div>
            </div>
          </div>
          <!-- .container -->
        </div>
      </div>
      <!-- .offer-section -->
      <div class="fullwidth-block news-notice">
        <div class="container">
          <div class="section-title">
            <div class="line"></div>
            <div class="txt">
              <span class="orange">新闻</span>与<span class="orange">公告</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="section-subtitle">News and Notice</div>
          <div class="row news-list">
            <div class="col-md-4" v-for="item in news" :key="item._id" @click="shownews(item._id)">
              <div class="news">
                <figure>
                  <img :src="item.cover" alt />
                </figure>
                <div class="date">
                  <img src="@/assets/images/icon-calendar.png" alt />
                  {{ item.createdAt | dateTime }}
                </div>
                <h2 class="entry-title">
                  <a href="javascript:;">
                    {{ item.title }}
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <!-- .row -->
        </div>
        <!-- .container -->
      </div>
      <div class="fullwidth-block partners">
        <div class="container">
          <div class="section-title">
            <div class="line"></div>
            <div class="txt"><span class="orange">合作</span>伙伴</div>
            <div class="line"></div>
          </div>
          <div class="section-subtitle">Partners</div>
          <div class="partner-list">
            <img src="@/assets/images/partner.png" style="width: 100%" />
          </div>
          <!-- .partners -->
        </div>
        <!-- .container -->
      </div>
    </main>
    <div class="tools">
      <div class="item" @click="switchWechat">
        <img src="@/assets/images/kefu.png" />
        <p>在线客服</p>
        <div :class="wechatstate ? 'wechatcode' : 'wechatcode hide'">
          <img src="@/assets/images/qrcode.png" style="width: 100%; height: 100%" alt="" class="wechat_qrimg" />
          <div class="util_ta_c">微信扫一扫</div>
        </div>
      </div>
      <div class="item" @click="switchWechat2">
        <img src="@/assets/images/wechat.png" />
        <p>关注微信</p>
        <div :class="wechatstate2 ? 'wechatcode' : 'wechatcode hide'">
          <img src="@/assets/images/we-qrcode.png" style="width: 100%; height: 100%" alt="" class="wechat_qrimg" />
          <div class="util_ta_c">联系客服</div>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/top.png" />
        <p>回到顶部</p>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Vue from "vue";
import "xe-utils";
import "vxe-table/lib/style.css";
import VXETable from "vxe-table";
Vue.use(VXETable);
import CountryRegions from "@/common/CountryRegions.vue";
export default {
  name: "Home",
  data() {
    return {
      showquery: false,
      wechatstate: false,
      wechatstate2: false,
      queryIndex: "price",
      currentIndex: 0,
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      news: [
        {
          _id: "d306933cd2c649559787ffc970edce1f",
          title: "关于正博部分产品调整的通知",
          summary: "关于正博部分产品调整的通知，部分产品降价",
          cover: require("@/assets/images/news-2.jpg"),
          createdAt: "2022-06-17 09:23:12",
        },
        {
          _id: "38eb86df40fc4f14b9facfe31b7649c1",
          title: "关于E邮宝郑州（普+特）-敦煌上线的通知",
          summary:
            "正博E邮宝产品和敦煌网合作，在敦煌网上线了一条优质线路——E邮宝郑州（普+特）-DF",
          cover: require("@/assets/images/news-3.jpg"),
          createdAt: "2022-06-02 16:25:02",
        },
        {
          _id: "e1d1466dddbc43c5ae29d71b64197549",
          title: "河南省商贸流通业第一批96家“四保”企业 | 名单",
          summary: "河南省商贸流通业第一批96家“四保”企业 | 名单，河南正博上榜",
          cover: require("@/assets/images/news-1.jpg"),
          createdAt: "2022-05-31 21:59:09",
        },
      ],
      code: "",
      countryregion: {
        title: "",
        _id: "",
        name: "",
        name_en: "",
        code: "",
      },
      cube_length: "",
      cube_width: "",
      cube_height: "",
      weight: "",
      track_codes: "",
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    CountryRegions,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this.check();
    window.onresize = () => {
      this.check();
    };
  },
  mounted() {
    console.log("home-mounted");
    console.log("Current Swiper instance object", this.swiper);
  },
  activated() {
    console.log("home-activated");
  },
  methods: {
    check() {
      this.showquery = window.innerWidth >= 1024;
    },
    switchWechat() {
      this.wechatstate = !this.wechatstate;
      this.wechatstate2 = false;
    },
    switchWechat2() {
      this.wechatstate2 = !this.wechatstate2;
      this.wechatstate = false;
    },
    switchClick(index) {
      this.queryIndex = index;
    },
    switchType(index) {
      this.currentIndex = index;
    },
    shownews(newsId) {
      this.$router.push({ path: "/newsdetail", query: { id: newsId } });
    },
    getdata() { },
    doQuery() {
      let code = this.countryregion.code;
      let weight = this.weight;
      let cube_length = this.cube_length;
      let cube_width = this.cube_width;
      let cube_height = this.cube_height;
      this.$router.push({
        path: "/pricequery",
        query: {
          code,
          weight,
          cube_length,
          cube_width,
          cube_height,
        },
      });
    },
    doTrack() {
      if (this.track_codes) {
        let track_codes = this.track_codes.split("\n");
        console.log(track_codes);
        if (track_codes.length > 0) {
          return this.$router.push({
            path: "/waybill",
            query: {
              code: track_codes.splice(0, 40).join(','),
            },
          });
        }
        // this.$utils.downloadBrowser({
        //   href: `https://t.17track.net/zh-cn#nums=${track_codes.join(",")}`,
        // });
      }
    },
    setcountryregion(data) {
      this.code = data.code;
      this.countryregion = data;
    },
  },
};
</script>
<style lang="less" scoped>
.main-content {}

.swiper {
  max-height: 588px;
  height: calc(100vw / 2);

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
}

.vxe-input {
  font-size: 16px;
  height: 48px;
}

.row.bulk {
  margin: 0;
}

.bulk .col-md-3 {
  padding: 0;
}

.query span {
  color: #fff;
}

.query span.x {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
  font-size: 20px;
  color: #fff;
}

.countrysbox ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  border-bottom: 1px solid #ddd;
}

.countrysbox {
  padding: 10px;
}

.countrysbox ul li {
  list-style: none;
  padding: 5px 0;
  font-size: 14px;
  cursor: pointer;
  display: inline;
  color: #355976;
}

.countrysbox ul li.active {
  border-bottom: 1px solid #286ed6;
}

.countrysbox .countrygroup {
  margin-top: 10px;
}

.countrysbox .countrygroup span {
  position: relative;
  width: 20%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: white;
  padding: 5px;
  right: auto;
  top: auto;
  word-wrap: break-word;
  color: #4a4a4a;
  font-size: 12px;
}

.partners .group {
  position: relative;
}

.partners .group img {
  width: 100%;
}

.product {
  cursor: pointer;
}

.feature-title {
  color: #999;
  margin-top: 10px;
}

.feature-title .num {
  color: #fa9600;
  font-size: 24px;
}

.tools {
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 1000;
}

.tools .item {
  cursor: pointer;
  background-color: #476cdd;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  text-align: center;
  padding: 6px;
  margin-bottom: 10px;
}

.tools .item img {
  width: 24px;
  height: 24px;
}

.tools .item p {
  margin: 0;
}

.tools .wechatcode {
  position: absolute;
  width: 200px;
  right: 76px;
  background: #fff;
  height: 200px;
  padding: 20px;
  color: #333;
  top: 0;
}

.tools .wechatcode.hide {
  display: none;
}

.products .pscontent .cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 288px;
  border-radius: 5px;
}

.product1 {
  background-image: url("~@/assets/images/products/1.png");
}

.product2 {
  background-image: url("~@/assets/images/products/2.png");
}

.product3 {
  background-image: url("~@/assets/images/products/3.png");
}

.product4 {
  background-image: url("~@/assets/images/products/4.png");
}

.product5 {
  background-image: url("~@/assets/images/products/5.png");
}

.news {
  cursor: pointer;
}
</style>
