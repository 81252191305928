<template>
  <div id="map" style="height:368px;"></div>
</template>
<script>
export default {
  name: "CompanyMap",
  data() {
    return {
      location: {
        name: "河南正博电子商务有限公司",
        latitude: 34.724889,
        longitude: 113.772233,
        address:
          "河南省郑州市经开区第九大街经北二路河南保税物流中心5楼",
      },
    };
  },
  mounted() {
    console.log("CompanyMap-mounted");
    this.init();
  },
  methods: {
    init() {
      let { name, address, latitude, longitude } = this.location;
      let center = new window.TMap.LatLng(latitude, longitude);
      let map = new window.TMap.Map(document.getElementById("map"), {
        center,
        zoom: 16,
        showControl: false,
        scrollable: false,
      });
      let marker = new window.TMap.MultiMarker({
        map,
        styles: {
          myStyle: new window.TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1",
            styleId: "myStyle",
            position: center,
            properties: {
              name,
              address,
              latitude,
              longitude,
            },
          },
        ],
      });
      marker.on("click", (evt) => {
        console.log(evt);
        this.$utils.downloadBrowser({
          href: `http://apis.map.qq.com/uri/v1/marker?marker=coord:${evt.geometry.properties.latitude},${evt.geometry.properties.longitude};title:${evt.geometry.properties.name};addr:${evt.geometry.properties.address}`,
        });
      });
      new window.TMap.InfoWindow({
        content: `<div id="infoWin" style="text-align: left;cursor: pointer;">
          <img src="/images/logo.png" width="48px" height="48px" style="display:inline-block;" />
          <div style="color:#F08301;">${name}</div>
          <div style="font-size:12px;">${address}</div>
        </div>`,
        position: center,
        offset: { x: 0, y: -30 },
        map,
      });
      setTimeout(() => {
        window.$("#infoWin").on("click", () => {
          this.$utils.downloadBrowser({
            href: `http://apis.map.qq.com/uri/v1/marker?marker=coord:${latitude},${longitude};title:${name};addr:${address}`,
          });
        });
      }, 1000);
    },
  },
};
</script>