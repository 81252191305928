<template>
  <div id="app">
    <component :is="getlayout"></component>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      current_layout: "empty-layout",
    };
  },
  computed: {
    getlayout() {
      return this.$route.meta.layout || this.current_layout;
    },
  },
};
</script>
