<template>
  <div class="business">
    <main class="main-content">
      <div class="breadcrumbs">
        <div class="container">
          <div class="d-flex">
            <h2>集团业务</h2>
            <div class="ol">
              <a href="javascript:;" @click="goto('/')">首页</a>
              <span>集团业务</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="container">
          <div class="row">
            <div class="col-md-2 product-list" style="padding:0;">
              <el-collapse v-model="activeName">
                <el-collapse-item title="航空包机" name="1">
                  <div :class="currentIndex == 11 ? 'item active' : 'item'" @click="changeProject(11)">空运包机</div>
                  <div :class="currentIndex == 12 ? 'item active' : 'item'" @click="changeProject(12)">机场运输</div>
                  <div :class="currentIndex == 13 ? 'item active' : 'item'" @click="changeProject(13)">地面操作</div>
                </el-collapse-item>
                <el-collapse-item title="电商产业园" name="2">
                  <div :class="currentIndex == 21 ? 'item active' : 'item'" @click="changeProject(21)">产业园布局</div>
                  <div :class="currentIndex == 22 ? 'item active' : 'item'" @click="changeProject(22)">产业园孵化</div>
                  <div :class="currentIndex == 23 ? 'item active' : 'item'" @click="changeProject(23)">电商人才培训</div>
                  <div :class="currentIndex == 24 ? 'item active' : 'item'" @click="changeProject(24)">产业园介绍</div>
                   <div :class="currentIndex == 25 ? 'item active' : 'item'" @click="changeProject(25)">合作共赢</div>
                </el-collapse-item>
                <el-collapse-item title="国内干线运输" name="3">
                  <div :class="currentIndex == 31 ? 'item active' : 'item'" @click="changeProject(31)">车辆货运</div>
                  <div :class="currentIndex == 32 ? 'item active' : 'item'" @click="changeProject(32)">车辆调度运营</div>
                </el-collapse-item>
                <el-collapse-item title="其他业务" name="4">
                  <div :class="currentIndex == 41 ? 'item active' : 'item'" @click="changeProject(41)">现场操作</div>
                  <div :class="currentIndex == 42 ? 'item active' : 'item'" @click="changeProject(42)">仓储服务</div>
                  <div :class="currentIndex == 43 ? 'item active' : 'item'" @click="changeProject(43)">人力资源服务</div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="col-md-10">
                <div class="mcontent" v-if="currentIndex === 11">
                    <img src="@/assets/images/air.png" height="445px" width="100%"/>
                </div>
                <div class="mcontent" v-if="currentIndex === 12">

                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .page -->
    </main>
  </div>
</template>
<script>
export default {
  name: "business",
  data() {
    return {
        activeName:['1','2','3'],
        currentIndex:11,
    };
  },
  components: {},
  mounted() {
    console.log("business-mounted");
  },
  activated() {
    console.log("business-activated");

  },
  methods: {
    changeProject(index){
        this.currentIndex = index;
    },
    goto(path) {
      if (this.$route.path === path) return;
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-collapse{ border: 1px solid #EBEEF5;}
/deep/ .el-collapse-item__header{padding-left: 10px;color: #fff; background-color: #0081CF;}
/deep/ .el-collapse-item__content{padding-bottom: 0;}
.product-list .item{cursor: pointer;padding: 10px 0 10px 20px; border-bottom: 1px solid #ddd; }
.product-list .item.active{font-weight: 500; color:#476CDD ;}
</style>