<template>
  <div class="priceresult">
    <div class="header">
      <div class="flex">
        <div class="tt">郑州市</div>
        <div><img src="@/assets/images/jt.png" width="128px" height="40px" /></div>
        <div class="tt">[{{ code }}]{{ name }}</div>
      </div>
      <div class="m">{{ weight }}KG {{ volume }}</div>
    </div>
    <div class="content">
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="product" label="产品类型" sortable>
          <template slot-scope="scope">
            <p class="title">{{ scope.row.name }}</p>
            <p class="info">{{ scope.row.note }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="price" label="价格失效" sortable>
          <template slot-scope="scope">
            <p class="ptitle">{{ scope.row.extra_fee + scope.row.freight }} RMB</p>
            <!-- <p class="info">{{ scope.row.date }}</p> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="empty">抱歉，目前没有查到该流向的报价信息。</div>
    </div>
    <div class="message">
      温馨提示：产品计费请以实际下单为准，列表内的价格仅供参考，详情请联系您的客户经理。
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceResult",
  data() {
    return {
      code: "",
      name: "",
      weight: "",
      cube_length: "",
      cube_width: "",
      cube_height: "",
      list: [],
    };
  },
  computed: {
    volume() {
      if (this.cube_length && this.cube_width && this.cube_height) {
        return `${this.cube_length}CM*${this.cube_width}CM*${this.cube_height}CM`;
      }
      return "";
    },
  },
  activated() {
    console.log("activated");
    let { code, name, weight, cube_length, cube_width, cube_height } = this.$route.query;
    this.code = code || "";
    this.name = name || "";
    this.weight = weight || "";
    this.cube_length = cube_length || "";
    this.cube_width = cube_width || "";
    this.cube_height = cube_height || "";
    if (code && weight) this.doQuery();
    else this.$router.replace("/pricetest");
  },
  methods: {
    doQuery() {
      this.$api.doAPI("/queryprice", { code: this.code, weight: this.weight, length: this.cube_length, width: this.cube_width, height: this.cube_height, }).then((res) => {
        if (res.code === 0) {
          this.list = res.data.list;
        }
      }).catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  padding: 20px 10px;
  border-bottom: 10px solid #eee;
  text-align: center;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;

    .tt {
      color: #000;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
}

.content {
  p {
    padding: 0;
    margin: 0;
  }

  .title {
    color: #000;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .info {
    color: #999;
    margin-top: 5px;
  }

  .ptitle {
    color: #e6a23c;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .empty {
    text-align: center;
    padding: 20px 0;
  }
}

.message {
  color: #e6a23c;
  background-color: #eee;
  padding: 20px 10px;
}
</style>
