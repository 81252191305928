<template>
  <div class="login">
    <main class="main-content">
      <div class="container">
        <div class="title">
          <h2>用户登录</h2>
        </div>
        <form action>
          <div class="row">
            <input
              v-model="username"
              type="text"
              class="input"
              placeholder="用户名"
              autocomplete="off"
            /><span class="red">*</span>
          </div>
          <div class="row">
            <input
              v-model="password"
              type="password"
              class="input"
              placeholder="密码"
              autocomplete="new-password"
            /><span class="red">*</span>
          </div>
          <div class="row valide">
            <input
              v-model="code"
              type="number"
              class="input"
              placeholder="图形验证码"
              autocomplete="off"
            />
            <a href="javascript:;" @click="refreshdata" class="svg">
              <img :src="img" />
            </a>
            <span class="red">*</span>
          </div>
        </form>
        <a href="javascript:;" class="button regbtn" @click="doLogin"> 立即登录 </a>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      code: "",
      uuid: "",
      img: "",
    };
  },
  activated() {
    console.log("login-activated");
    this.refreshdata();
  },
  methods: {
    refreshdata() {
      this.$api
        .doAPI("/authcode")
        .then((res) => {
          console.log("authcode", res);
          this.uuid = res.uuid;
          this.img = res.img;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    doLogin() {
      if (!this.username || !this.password || !this.code) return;
      this.$api
        .doAPI("/login", {
          username: this.username,
          password: this.password,
          code: this.code,
          uuid: this.uuid,
        })
        .then((res) => {
          if (res.code === 0) {
            console.log(res);
            //TODO
            this.$swal.fire({
              title: "登录成功",
              icon: "success",
              confirmButtonText: "确定",
              confirmButtonColor: "rgba(165,220,134,1)",
            });
          } else {
            this.$swal.fire({
              title: res.msg,
              icon: "error",
              confirmButtonText: "关闭",
              confirmButtonColor: "#f27474",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.main-content {
  margin: 30px 0;
  height: 50vh;
}
.container {
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding: 20px;
}
.container .row {
  margin: 20px 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .row .red {
  color: red;
  margin-left: 5px;
}
.container input {
  width: 100%;
}
.container .regbtn {
  width: 100%;
}
.container .row.valide {
  display: flex;
}
.container .row.valide .input {
  flex: 4;
}
.container .row.valide .button {
  flex: 1;
  margin-left: 5px;
}
.container .row.disclaimer {
  display: flex;
}
</style>
