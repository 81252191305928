<template>
  <div class="sign">
    <div class="cover">
      <van-image width="100%" height="228px" :src="require('@/assets/images/bk_sign.jpg')" />
    </div>
    <div class="signform">
      <div class="header">
        2024年亚马逊河南跨境电商高质量发展对接会
      </div>
      <van-form @submit="onSubmit">
        <van-cell-group>
          <label class="lbltitle">姓名<span style="color: red;">*</span>:</label>
          <van-field v-model="signForm.name" placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <label class="lbltitle">手机号<span style="color: red;">*</span>:</label>
          <van-field v-model="signForm.phone" type="tel" placeholder="请输入手机号" />
        </van-cell-group>
        <van-cell-group>
          <label class="lbltitle">公司<span style="color: red;">*</span>:</label>
          <van-field v-model="signForm.company" placeholder="请输入贵公司名称" />
        </van-cell-group>
        <van-cell-group>
          <label class="lbltitle">开店时长<span style="color: red;">*</span>:</label>
          <van-field readonly clickable :value="signForm.duration" placeholder="选择您的开店时长" @click="showPicker = true" />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <label class="lbltitle">您最期待解决的问题:</label>
          <van-field v-model="signForm.question" rows="3" type="textarea" autosize placeholder="请输入您的问题" />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
      <div class="footer">
        © 2024 正博产业园版权所有
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sign",
  data() {
    return {
      signForm: { name: '', phone: '', company: '', duration: '', question: '' },
      columns: ["1年以下", "1年", "2年", "3年", "3年以上"],
      showPicker: false,
      duration: '',
    };
  },
  mounted() {
    window.document.title = '2024年亚马逊河南跨境电商高质量发展对接会';
  },
  methods: {
    initForm() {
      this.signForm = { name: '', phone: '', company: '', duration: '', question: '' };
    },
    onSubmit() {
      this.$api.doAPI("/sign", { _from: "1", signForm: this.signForm }).then((res) => {
        if (res.code === 0) {
          this.$dialog.alert({ title: '恭喜', message: '报名成功，感谢参与' }).then(() => {
            this.initForm();
          });
        } else {
          this.$dialog.alert({ title: '提示', message: res.msg })
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    onConfirm(value) {
      this.signForm.duration = value;
      this.showPicker = false;
    },
  },
};
</script>
<style lang="less" scoped>
.signform {
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  background: #fff;
  border-radius: 30px;

  .header {
    padding: 20px 15px;
    font-size: 20px;
    font-weight: 500;
  }

  .lbltitle {
    padding: 10px 15px;
  }

  /deep/ input {
    border: 1px solid #ddd;
    box-shadow: none;
    padding: 10px;
    font-size: 16px;
  }

  /deep/ textarea {
    border: 1px solid #ddd;
    box-shadow: none;
    padding: 10px;
  }

  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: 0;
  }

  .footer {
    text-align: center;
    font-size: 12px;
  }
}
</style>
